export default {
  adherence:
    "When a patient takes a medication exactly as prescribed (i.e. correct dose, correct administration, correct duration of therapy).",

  "brick-and-mortar":
    "Physical pharmacy stores. These may also use a website, patient portal, phone line, or app to conduct business, but largely depend on customers coming into the store.",

  "chain pharmacies":
    "Major pharmacy retailers with many locations nationwide. E.g. CVS, Walgreens, etc.",

  "chronic condition":
    "A long-term disease or condition, variously defined as lasting at least 3-12 months, and requiring ongoing care or therapy.",

  "Digital Pharmacy Accreditation":
    "Signifies that an online pharmacy complies with the National Association of Boards of Pharmacy criteria, which includes patient privacy, order security, quality assurance, and provision of meaningful consultation between customers and pharmacists.",

  "DIR fees":
    "Direct and Indirect Remuneration fees. Various fees collected by a PBM from pharmacies for a variety of reasons.",

  "e-commerce":
    "Pharmacies without physical locations that conduct business entirely online.",

  "inventory management":
    "The process of maintaining an adequate stock of medications in order to avoid too little or too much stock on the pharmacy shelves.",

  "maintenance medications":
    "Prescriptions taken at regular intervals (usually daily) to treat chronic conditions.",

  "medication therapy management":
    "Broad range of medication services administered by pharmacists. Often centers around reviewing a patient's prescriptions, ensuring they understand why and how to take them, and monitoring their progress on therapy.",

  OTC: "Over-the-counter, that is, not prescribed.",

  PBM:
    "Pharmacy benefit manager. Third-party companies that manage patients' prescription drug benefits on behalf of an insurer.",

  "pharmacy software systems":
    "Software implemented by pharmacies to manage their operations.",

  "prior authorization":
    "Approval from the health plan or PBM that is required for payment of certain medications.",

  reimbursements:
    "Payments made to a pharmacy after a prescription is billed to the patient's PBM and the PBM accepts the claim.",

  "return to stock":
    "When prescriptions are dispensed but not picked up by the patient, the medication must be put back into stock containers. Some states have strict guidelines on returning to stock to ensure labels are appropriately destroyed and batches of medication are not mixed.",

  "service area": "Geographic area served by the delivery solution.",

  "service levels":
    "In regards to delivery, the turnaround time from delivery request to completion. Service levels could include on-demand, same-day, next-day, shipping, expedited shipping, etc.",

  shipping:
    "Delivery completed by the USPS or other package carriers and transported by ground or air.",

  telehealth:
    "Healthcare and health consultations delivered via telephone, online portal, or other telecommunications means.",
}
