import React, { useEffect } from "react"

import { Parallax } from "react-scroll-parallax"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MarkdownContent from "../components/markdown_content"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Image from "../components/image"

import DotSeparator from "../components/decorative/dotseparator"
import MarchingPills from "../components/decorative/marchingpills"

import PillGroupLeft from "../images/decoration/pill-group-l.svg"
// import PillGroupRight from "../images/decoration/pill-group-r.svg"

import GlossaryData from "../components/glossary_data"

const IndexPage = ({ data }) => {
  const Content = ({ slug }) => <MarkdownContent slug={slug} data={data} />

  useEffect(() => {
    document.querySelectorAll("a.term").forEach(el => {
      const term = el.getAttribute("data-term") || el.innerHTML
      const definition = GlossaryData[term]
      el.setAttribute("href", "#glossary")
      el.setAttribute("data-tooltip", definition)
      el.classList.toggle("tooltip-multiline", true)
    })
  })

  return (
    <Layout>
      <SEO title="Home" />

      <div className="wash-blue-steel-p2">
        <section id="summary" className="container padded">
          <Content slug="01-executive-summary" />
        </section>
      </div>

      <div className="wash-white">
        <section id="welcome">
          <div className="container padded">
            <Content slug="02-welcome" />
          </div>
        </section>

        <DotSeparator />

        <section id="home-as-a-site-of-care">
          <div className="container padded extra-padded-bottom">
            <Content slug="03-home-as-a-site-of-care" />
          </div>
        </section>
        
      </div>

      <section className="padded-top" id="social-determinants-of-health">
        <div className="wash-goldenrod">
          <MarchingPills />

          <div className="container padded-bottom">
            <Content slug="04-00-social-determinants-of-health-wash" />
          </div>
        </div>

        <div className="wash-white extra-padded-bottom">

          <Content slug="04-01-social-determinants-of-health-main" />

        </div>
      </section>


      <section id="delivery-overcoming-obstacles">
        <div className="wash-foam">
          <MarchingPills />
          
          <div className="container padded-bottom">
            <Content slug="05-00-overcoming-obstacles-wash" />
          </div>
        </div>

        <div className="wash-white">
          <div className="container padded">
            <Content slug="05-01-overcoming-obstacles-main" />
          </div>

          <DotSeparator />
        </div>
      </section>
      
      <section id="major-players">
        <div className="wash-royal">
          <div className="container padded">
            <Content slug="09-00-players-wash" />
          </div>
        </div>

        <div className="wash-white padded-bottom">
          <div className="container padded">
            <Content slug="09-01-players-intro" />
          </div>

          <Tabs>
            <TabList>
              <Tab><strong>B2C</strong><br/>Digital Pharmacy</Tab>
              <Tab><strong>B2C</strong><br/>Retail Pharmacy</Tab>
              <Tab><strong>B2C</strong><br/>PBM Mail Order</Tab>
              <Tab><strong>B2B</strong><br/>Prescription Software/Services</Tab>
            </TabList>

            <TabPanel>
              <Content slug="09-02-players-digital-pharmacy" />
            </TabPanel>
            <TabPanel>
              <Content slug="09-02-players-retail-pharmacy" />
            </TabPanel>
            <TabPanel>
              <Content slug="09-02-players-pbm-mail-order" />
            </TabPanel>
            <TabPanel>
              <Content slug="09-02-players-prescription-software" />
            </TabPanel>
          </Tabs>

        </div>
      </section>

      <div className="wash-white">
        <section id="conclusion" >
          <div className="wash-navy">
            <div className="container padded">
              <Content slug="07-00-conclusion-wash" />
            </div>
          </div>

          <div className="container padded">
            <Content slug="07-01-conclusion-main" />
          </div>
        </section>


        <DotSeparator />

        <section id="advisory-council">
          <div className="container padded">
            <Content slug="13-00-advisory" />
          </div>
        </section>

        <section id="glossary">
          <div className="container padded">
            <Content slug="12-00-glossary" />
          </div>
        </section>

        <section id="sources">
          <div className="container padded sources">
            <Content slug="14-00-sources" />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark {
      nodes {
        html
        frontmatter {
          slug
        }
      }
    }
  }
`

export default IndexPage
