import React from "react"
import { useInView } from "react-intersection-observer"

const MarkdownContent = ({ slug, data }) => {
  const node = data.allMarkdownRemark.nodes.find(
    c => c.frontmatter.slug === slug
  )

  const html = !!node ? node.html : `Missing content: ${slug}`

  const [ref, inView] = useInView()

  return (
    <div
      className={`md-content ${inView ? `in-view` : "out-of-view"}`}
      ref={ref}
      id={slug}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default MarkdownContent
