import React from "react"
import { Parallax } from "react-scroll-parallax"
import PillTrain from "../../images/decoration/pill-train.svg"

const MarchingPills = () => (
  <Parallax
    x={[-3, 3]}
    tagOuter="div"
    styleOuter={{
      position: "relative",
      overflowX: "hidden",
      overflowY: "visible",
      width: "100vw",
      top: "-4em",
      height: "6em",
      marginBottom: "-2em",
    }}
    styleInner={{
      position: "absolute",
      width: "200%",
      height: "90%",
      top: 0,
      left: "-50%",
      backgroundImage: `url(${PillTrain})`,
      backgroundRepeat: "repeat-x",
      backgroundSize: "contain",
    }}
  ></Parallax>
)

export default MarchingPills
