import React from "react"
import { Parallax } from "react-scroll-parallax"

const DotSeparator = () => {
  return (
    <div className="container padded extra-padded-bottom">
      <span
        role="separator"
        className="dot-separator"
        style={{
          position: "relative",
          display: "block",
          width: "5vw",
          margin: "0 auto",
        }}
      >
        <Parallax
          x={["-10px", "-50px"]}
          styleOuter={{ position: "absolute", top: 0, left: 0 }}
          styleInner={{
            width: "10px",
            height: "10px",
            borderRadius: "5px",
            background: "#ffbf6f",
          }}
        ></Parallax>

        <Parallax
          x={["0px", "0px"]}
          styleOuter={{ position: "absolute", top: 0, left: "50%" }}
          styleInner={{
            width: "10px",
            height: "10px",
            borderRadius: "5px",
            background: "#8d80f1",
            marginLeft: "-5px",
          }}
        ></Parallax>

        <Parallax
          x={["10px", "50px"]}
          styleOuter={{ position: "absolute", top: 0, right: 0 }}
          styleInner={{
            width: "10px",
            height: "10px",
            borderRadius: "5px",
            background: "#00a8fe",
          }}
        ></Parallax>
      </span>
    </div>
  )
}

export default DotSeparator
